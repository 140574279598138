import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminSupportChats from "../../Pages/AdminSupportChats";
import AboutUs from "../../Pages/CMS/AboutUs";
import ContactUs from "../../Pages/CMS/ContactUs";
import EmailTemplates from "../../Pages/CMS";
import PrivacyPolicy from "../../Pages/CMS/PrivacyPolicy";
import FAQ from "../../Pages/CMS/FAQ";
import ScheduledNotification from "../../Pages/CMS/ScheduledNotification";
import TermAndConditions from "../../Pages/CMS/TermAndConditions";
import Dashboard from "../../Pages/Dashboard";
import ConciergeRequest from "../../Pages/Subscriptions/ConciergeRequest";
import FeatureCost from "../../Pages/Subscriptions";
import PremimumSignup from "../../Pages/Subscriptions/PremimumSignup";
import UserWallet from "../../Pages/Subscriptions/UserWallet";
import AllUser from "../../Pages/UserMangement";
import PendingProfiles from "../../Pages/UserMangement/PendingProfiles";
import PremiumUsers from "../../Pages/UserMangement/PremiumUsers";
import RequestedProfile from "../../Pages/UserMangement/RequestedProfile";
import VerificationRequest from "../../Pages/VerificationRequest";
import FinanceReport from "../../Pages/Subscriptions/FinanceReport";
import InactiveUsers from "../../Pages/UserMangement/InactiveUsers";
import Transactions from "../../Pages/UserMangement/Transactions";
import AddNewFeature from "../../Pages/Subscriptions/AddNewFeature";
import AddSuggestionForm from "../../Pages/UserMangement/AddSuggestionForm";
import AddNewTemplate from "../../Pages/CMS/AddNewTemplate";
import AddNewNotification from "../../Pages/CMS/AddNewNotification";
import ViewEmailTemplate from "../../Pages/CMS/ViewEmailTemplate";
import PersonalityInformation from "../../Pages/CMS/PersonalityInformation";
import UserChatGame from "../../Pages/CMS/UserChatGame";
import AdminProfieDetails from "../../Components/Common/AdminProfieDetails";
import Events from "../../Pages/Events";
import EventsDetails from "../../Pages/Events/EventsDetails";
import ViewNotification from "../../Pages/CMS/ViewNotification";
import EditEmailTemplate from "../../Pages/CMS/EditEmailTemplate";
import AboutMeQuestions from "../../Pages/CMS/AboutMeQuestions";
import FeaturesFreeQuota from "../../Pages/CMS/FeaturesFreeQuota";
import ViewPackage from "../../Pages/Subscriptions/ViewPackage";
import DashboardDataShowTable from "../../Pages/Dashboard/DashboardDataShowTable";
import DashboardDataAllUser from "../../Pages/Dashboard/DashboardDataAllUser";
import DashboardDataForUserSignUp from "../../Pages/Dashboard/DashboardDataForUserSignUp";
import DashboardDataForRevenue from "../../Pages/Dashboard/DashboardDataForRevenue";
import Report from "../../Pages/Report/Report";
import BanUser from "../../Pages/UserMangement/BanUsers";
import AdminManagement from "../../Pages/Staff Management/AdminManagement";
import ViewAdmin from "../../Pages/Staff Management/viewAdminManagement";
import ProtectedRoute from "./ProtectedRoute";
import { useDispatch } from "react-redux";
import { getUserData } from "../../redux/action/action";
import { useEffect } from "react";

const Router = () => {
  const dispatch = useDispatch()

  useEffect(async()=>{
      dispatch(await getUserData())
  },[])

  return (
    <>
      <Routes>
        {/* <Route path='/login' exact element={<Login/>}/> */}
        <Route path="/profiledetails" element={<AdminProfieDetails />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />

        {/* <Route path='usermanagement' element={<AllUser/>}/> */}
        <Route path="/usermanagement">
          {/* <Route path='alluser' element={<><AllUser/> <Outlet/></>}> */}
          {/* <Route path='alluser' element={<AllUser/>}>
                <Route  path='transactions' element={<Transactions/>}/>
            </Route> */}
          <Route path="allusers" element={<AllUser />} />
          <Route path="userdetails" element={<Transactions />} />
          <Route
            path="userdetails/addsuggestion"
            element={<AddSuggestionForm />} 
          />
          <Route path="premiumusers" element={<PremiumUsers />} />
          <Route path="banusers" element={<BanUser />} />
          <Route path="verificationRequest" element={<PendingProfiles />} />
          <Route path="profiledeletion" element={<RequestedProfile />} />
          <Route path="inactiveusers" element={<InactiveUsers />} />
          <Route
            path="dashboardData/PremiumSubscriptions"
            element={<DashboardDataShowTable />}
          />
          <Route
            path="dashboardData/Revenue"
            element={<DashboardDataForRevenue />}
          />
          <Route
            path="dashboardData/NewUser"
            element={<DashboardDataShowTable />}
          />
          <Route
            path="dashboardData/Religion"
            element={<DashboardDataAllUser />}
          />
          <Route
            path="dashboardData/UserSignUp"
            element={<DashboardDataForUserSignUp />}
          />
        </Route>

        <Route
          path="/staffmanagement/admin"
          element={
            <ProtectedRoute>
              {" "}
              <AdminManagement />
            </ProtectedRoute>
          }
        />

        <Route
          path="/staffmanagement/admin/add"
          element={
            <ProtectedRoute>
              {" "}
              <ViewAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/staffmanagement/admin/edit"
          element={
            <ProtectedRoute>
              {" "}
              <ViewAdmin />
            </ProtectedRoute>
          }
        />
        {/* <Route path='subsciption' element={<FeatureCost/>}/> */}
        <Route path="/subsciption">
          <Route path="userdetails" element={<Transactions />} />
          <Route
            path="userdetails/addsuggestion"
            element={<AddSuggestionForm />}
          />
          <Route path="conciergerequest" element={<ConciergeRequest />} />
          <Route path="packages" element={<FeatureCost />} />
          <Route path="packages/addnewpackage" element={<AddNewFeature />} />
          <Route path="packages/editpackage" element={<ViewPackage />} />
          <Route path="premimumsignups" element={<PremimumSignup />} />
          <Route path="userwallets" element={<UserWallet />} />
          <Route path="financereport" element={<FinanceReport />} />
        </Route>
        <Route path="/pendingVerification" element={<VerificationRequest />} />
        <Route path="/adminsupportchats" element={<AdminSupportChats />} />
        <Route path="/userReports" element={<Report />} />
        {/* <Route path='cms' element={<EmailTemplates/>}/> */}
        <Route path="/cms">
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="emailtemplates" element={<EmailTemplates />} />
          <Route
            path="emailtemplates/addnewtemplate"
            element={<AddNewTemplate />}
          />
          <Route
            path="emailtemplates/updateEmailTemplate"
            element={<EditEmailTemplate />}
          />
          <Route path="viewemailtemplate" element={<ViewEmailTemplate />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="faq" element={<FAQ />} />
          <Route
            path="schedulednotifications"
            element={<ScheduledNotification />}
          />
          <Route
            path="schedulednotifications/addnewnotification"
            element={<AddNewNotification />}
          />
          <Route
            path="schedulednotifications/editNotification"
            element={<ViewNotification />}
          />
          <Route path="termsandconditions" element={<TermAndConditions />} />
          <Route
            path="personalityinformation"
            element={<PersonalityInformation />}
          />
          <Route path="chatgame" element={<UserChatGame />} />
          <Route path="aboutMeQuestions" element={<AboutMeQuestions />} />
          <Route path="featuresFreeQuota" element={<FeaturesFreeQuota />} />
        </Route>
        <Route path="/events" element={<Events />} />
        <Route path="/events/eventDetails" element={<EventsDetails />} />
      </Routes>
    </>
  );
};

export default Router;
