import { Footer } from "antd/lib/layout/layout";
import React from "react";
import { Link } from "react-router-dom";

const PageFooter = () => {
  return (
    <div
      style={{
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        textAlign: "justify",
        color: "#455560",
       padding:"17px",
      }}
    >
      Copyright © <span>{new Date().getFullYear()}</span> Joompa pte.ltd. All
      Rights Reserved
      <span style={{ float: "right" }}>
      <a href="https://main-staging.letsjoompa.com/terms-and-conditions"> Terms & Conditions</a> | <a href="https://main-staging.letsjoompa.com/privacy-policy">Privacy & Policy</a> 
      </span>
    </div>
  );
};

export default PageFooter;
