import { message } from 'antd';
import axios from 'axios';
let authToken =localStorage.getItem('Auth_Token') || sessionStorage.getItem('Auth_Token')
axios.defaults.headers.common['token'] = authToken;
const joompaApi = axios.create({
  // baseURL: 'https://joompa-0bka.onrender.com',
  baseURL: "https://staging-api.letsjoompa.com",
  headers: {
    "Content-Type": "application/json",
  },
});
 

  joompaApi.interceptors.response.use(function (response) {          
    return response;   
  }, function (error) {     
    if(error.response.data==="Unauthorized"){
      message.error("Session Expired")
     
      sessionStorage.removeItem("Auth_Token")
      localStorage.removeItem('Auth_Token')
      setTimeout(()=>{
        window.location.reload()
      },200)
      return;
    }
    throw error;
  });

export default joompaApi;